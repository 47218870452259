function emailChainStatus(email_chain_id) {
  var elem = $(".email-chain#" + email_chain_id);

  var statuses = elem.children(".email-response").map(function() {
    return $(this).data("manual-status");
  }).get()

  if (statuses.includes("interested")) {
    return "interested";
  } else if (statuses.includes("response")) {
    return "response";
  } else {
    return "pending";
  }
}

export function initEmailResponses() {
  $(".email-responses .email-response a.set-status").click(function(event) {
    var elem = $(this);
    var url = elem.attr("href");
    event.preventDefault();

    $.post(url, function(data) {
      var email_response_id = data["email_response_id"];
      var email_chain_id = data["email_chain_id"];
      var email_chain_elem = $(".email-chain#" + email_chain_id);
      var email_response_elem = $(".email-response#" + email_response_id);
      var email_chain_status_before = emailChainStatus(email_chain_id);
      var to_status = data["to"];

      email_response_elem.data("manual-status", to_status);
      email_response_elem.find(".fancy-button").removeClass("active");
      email_response_elem.find(".fancy-button." + to_status).addClass("active");

      var email_chain_status_after = emailChainStatus(email_chain_id);

      if (email_chain_status_before != email_chain_status_after) {
        var from_count_elem = $("#status-count-" + email_chain_status_before);
        var from_count = parseInt(from_count_elem.text(), 10);
        var to_count_elem = $("#status-count-" + email_chain_status_after);
        var to_count = parseInt(to_count_elem.text(), 10);

        from_count_elem.text(from_count - 1);
        to_count_elem.text(to_count + 1);
      }

      var statuses = email_chain_elem.children(".email-response").map(function() {
        return $(this).data("manual-status");
      }).get();

      if (!statuses.includes("") || statuses.includes("interested")) {
        email_chain_elem.remove();
      }

      if ($(".email-chain").length === 0) {
        location.reload(true);
      }
    });
  })

  $(".email-responses-form").change(function(event) {
    if (event.target.id === "company_id") {
      if ($(".email-responses-form #credit_batch_id").length) {
        $(".email-responses-form #credit_batch_id").val("all");
      }
    }

    $(this).submit();
  });

  $(".email-responses a.set-pending-as-response").click(function(event) {
    event.preventDefault();

    const url = $(this).attr("href")
    const status_updates = {}

    $(".email-chain").each(function() {
      $(this).children(".email-response").each(function() {
        if ($(this).data("manual-status") === "") {
          status_updates[$(this).attr("id")] = "response"
        }
      })
    })

    $.post(url, {statuses: status_updates}, function(data) {
      location.reload(true)
    })
  })

  $(".email-responses a.set-pending-for-email-chain-as-response").click(function(event) {
    event.preventDefault();

    const url = $(this).attr("href")
    const status_updates = {}
    const email_chain_elem = $(this).parent(".email-chain")

    $(this).parent(".email-chain").children(".email-response").each(function() {
      if ($(this).data("manual-status") === "") {
        status_updates[$(this).attr("id")] = "response"
      }
    })

    $.post(url, {statuses: status_updates}, function(data) {
      email_chain_elem.remove();

      if ($(".email-chain").length === 0) {
        location.reload(true);
      }
    })
  })
}

export function initFullThreadEmailResponses() {
  function changeStatus(event) {
    var elem = $(this);
    var links = elem.siblings("a.set-status").addBack();
    var url = elem.attr("href");
    event.preventDefault();

    links
      .children(".status-btn")
      .removeClass("active")
      .removeClass("ai-thinks");

      $.post(url, function (data) {
      
      links
        .children(".status-btn.".concat(data["to"]))
        .addClass("active");
    });
  }

  $(
    "full-thread .email-response a.set-status, .set-status-buttons a.set-status"
  ).off("click.changeStatus");

  $(
    "full-thread .email-response a.set-status, .set-status-buttons a.set-status"
  ).on("click.changeStatus", changeStatus);

}
